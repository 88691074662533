import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiOutlineAudit } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import {
	FaFileCode,
	FaGlobe,
	FaMapMarkerAlt,
	FaMobileAlt,
	FaShoppingBasket,
} from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiPagesLine } from "react-icons/ri";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OASection from "../components/our-approach-section";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const SEOPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			visibleImg: wpMediaItem(title: { eq: "visible" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "SEO London" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			puzzleImg: wpMediaItem(title: { eq: "ojc" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "SEO" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpNewSeoPage {
				seoPageFields {
					benefitsSection {
						title
						benefit1 {
							description
							title
						}
						benefit2 {
							description
							title
						}
						benefit3 {
							description
							title
						}
						button {
							link
							text
						}
					}
					bookAMeetingSection {
						description
						title
					}
					fourStepSection {
						button {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						step1 {
							title
							description
						}
						step2 {
							title
							description
						}
						step3 {
							title
							description
						}
						step4 {
							title
							description
						}
						title
					}
					heroSection {
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
						description
						title
					}
					resultsDrivenSection {
						button {
							link
							text
						}
						description
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					typesOfSeoSection {
						title
						type1 {
							description
							title
						}
						type2 {
							description
							title
						}
						type3 {
							description
							title
						}
						type4 {
							description
							title
						}
						type5 {
							description
							title
						}
						type6 {
							description
							title
						}
						type7 {
							description
							title
						}
						type8 {
							description
							title
						}
						type9 {
							description
							title
						}
					}
					websiteAtTheTopSection {
						title
						description
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	const {
		wpNewSeoPage: {
			seoPageFields: {
				benefitsSection,
				bookAMeetingSection,
				fourStepSection,
				heroSection,
				resultsDrivenSection,
				typesOfSeoSection,
				websiteAtTheTopSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO",
				item: {
					url: `${siteUrl}/seo`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5  py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<div>{parse(websiteAtTheTopSection.title)}</div>
								<div>{parse(websiteAtTheTopSection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 mb-4 mb-md-0 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={websiteAtTheTopSection.button1.link}
								>
									{websiteAtTheTopSection.button1.text}
								</Button>
								<Button
									className=" w-100 cta-btn w-md-auto fs-5 ms-4 d-none d-xl-inline-block white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="primary"
									as={Link}
									to={websiteAtTheTopSection.button2.link}
								>
									{websiteAtTheTopSection.button2.text}
								</Button>
							</Col>
							<Col className="mt-4 mt-lg-0" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={websiteAtTheTopSection.image.gatsbyImage}
										alt={websiteAtTheTopSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="">
						<Row>
							<Col>
								<div>{parse(benefitsSection.title)}</div>
							</Col>
						</Row>
						<Row className="gx-xl-9 g-6 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={blueOne} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">
										{benefitsSection.benefit1.title}
									</h3>
									<p className="text-white">
										{benefitsSection.benefit1.description}
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={blueTwo} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">
										{benefitsSection.benefit2.title}
									</h3>
									<p className="text-white">
										{benefitsSection.benefit2.description}
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
									<img src={blueThree} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">
										{benefitsSection.benefit3.title}
									</h3>
									<p className="text-white">
										{benefitsSection.benefit3.description}
									</p>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-5 mt-md-7 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									as={Link}
									to={benefitsSection.button.link}
								>
									{benefitsSection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="results" className="bg-light-grey pb-5 pb-lg-7">
					<Container>
						<Row className="align-items-center py-5 py-xl-0">
							<Col lg={{ span: 8, order: "last" }}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={resultsDrivenSection.image.gatsbyImage}
										alt={resultsDrivenSection.image.altText}
									/>
								</div>
							</Col>
							<Col lg={4} className="  mt-lg-0">
								<div>{parse(resultsDrivenSection.title)}</div>
								<div>{parse(resultsDrivenSection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={resultsDrivenSection.button.link}
								>
									{resultsDrivenSection.button.text}
								</Button>
							</Col>
						</Row>
						<Row className="align-items-center our-results py-lg-4 mt-md-4 mt-lg-0 py-md-5  gy-5 gy-lg-0">
							<Col className="text-center px-4 " xs={12} lg={true}>
								<div className="">
									<GatsbyImage
										className="mw-100 "
										image={data.puzzleImg.gatsbyImage}
										alt={data.puzzleImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="ssp-bold text-center text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center pb-4" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">10%</p>
								<h3 className="fs-5">Conversion</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">3</p>
								<h3 className="fs-5">#1 high-volume keywords</h3>
							</Col>
							<Col className="text-center pb-4" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">10x</p>
								<h3 className="fs-5">ROI</h3>
							</Col>
						</Row>
						<Row className="align-items-center  our-results mt-md-5  py-md-5 py-lg-0 gy-5 gy-lg-0">
							<Col className="text-center px-4 py-md-4" xs={12} lg={true}>
								<div className="bg-jason p-4">
									<GatsbyImage
										className="mw-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="ssp-bold text-center text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">3</p>
								<h3 className="fs-5">Month ROI</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">
									200%
								</p>
								<h3 className="fs-5">Increase traffic</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">5x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
						<Row className="align-items-center our-results mt-md-5 py-5 py-lg-0 gy-5 gy-lg-0">
							<Col xs={12} className="text-center px-4 py-md-4" lg={true}>
								<div className="bg-black p-4">
									<GatsbyImage
										className="mw-100 "
										image={data.seymourImg.gatsbyImage}
										alt={data.seymourImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="ssp-bold text-center text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">5</p>
								<h3 className="fs-5">Locations at #1</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">50%</p>
								<h3 className="fs-5">Increase in CTR</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="ssp-bold text-primary pb-1 mb-0 display-4">2x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<div>{parse(typesOfSeoSection.title)}</div>
							</Col>
						</Row>
						<Row className="gy-5">
							<Col md={6} lg={4} className="text-md-center">
								<FaMapMarkerAlt className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type1.title}
								</h3>
								<p>{typesOfSeoSection.type1.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaShoppingBasket className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type2.title}
								</h3>
								<p>{typesOfSeoSection.type2.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaFileCode className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type3.title}
								</h3>
								<p>{typesOfSeoSection.type3.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaGlobe className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type4.title}
								</h3>
								<p>{typesOfSeoSection.type4.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<FaMobileAlt className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type5.title}
								</h3>
								<p>{typesOfSeoSection.type5.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<RiPagesLine className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type6.title}
								</h3>
								<p>{typesOfSeoSection.type6.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<BsGraphUp className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type7.title}
								</h3>
								<p>{typesOfSeoSection.type7.description}</p>
							</Col>
							<Col lg={4} className="text-md-center">
								<IoMdAnalytics className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type8.title}
								</h3>
								<p>{typesOfSeoSection.type8.description}</p>
							</Col>
							<Col md={6} lg={4} className="text-md-center">
								<AiOutlineAudit className="display-5 text-primary" />
								<h3 className="ssp-bold my-4">
									{typesOfSeoSection.type9.title}
								</h3>
								<p>{typesOfSeoSection.type9.description}</p>
							</Col>
						</Row>
					</Container>
				</section>
				<div id="approach">
					<OASection
						imgHeight="45rem"
						customHeading
						heading={fourStepSection.title}
						image={fourStepSection.image.gatsbyImage}
						imageAlt={fourStepSection.image.altText}
						heading1={fourStepSection.step1.title}
						heading2={fourStepSection.step2.title}
						heading3={fourStepSection.step3.title}
						heading4={fourStepSection.step4.title}
						launch={fourStepSection.step4.description}
						build={fourStepSection.step3.description}
						design={fourStepSection.step2.description}
						brief={fourStepSection.step1.description}
						buttonUrl={fourStepSection.button.link}
						buttonText={fourStepSection.button.text}
					/>
				</div>
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Seo" />
				</section> */}
				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="text-primary fs-1 mb-5">Our SEO blog posts</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-7 h-100">
							{data.allWpPost.nodes
								.filter(
									(blog, index) =>
										blog.title === "What is SEO?" ||
										blog.title ===
											"Top 10 SEO checks to perform on your website for 2023"
								)
								.map((blog, index) => (
									<Col style={{ minHeight: "100%" }} lg={6}>
										<div
											className="bg-white position-relative"
											style={{
												overflow: "hidden",
												borderRadius: "20px",
												boxShadow: "0px 3px 60px #00000029",
												minHeight: "100%",
											}}
										>
											<GatsbyImage
												image={blog.blogFields.featuredImage.gatsbyImage}
												alt={blog.blogFields.featuredImage.altText}
												className="w-100 seo-blog-image"
											/>
											<div className="p-4">
												<h2 className="fs-4 pe-lg-10 mb-4 text-primary ssp-bold">
													{blog.title}
												</h2>
												<div className="w-100" style={{ height: "25px" }}></div>
												<Link
													to={`/blog/${convertToSlug(
														blog.blogFields.category
													)}/${blog.slug}`}
													className="med-grey-link ssp-bold position-absolute bottom-0 pb-4"
												>
													READ BLOG
												</Link>
											</div>
										</div>
									</Col>
								))}
						</Row>
					</Container>
				</section>
				<div id="faq">
					<Faq page="SEO" />
				</div>
				{/* <OSSection
					link1="/training"
					text1="TRAINING"
					link3="/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default SEOPage;
